import {GET} from './config'


// export function swiper() {
//     return axios({
//         url: '/au/pavlion/lindex',
//         method: "GET",
//         headers: {
//             "X-Sign": sign(),
//           },
//     })
// }
export const swiper = async (params = {}) => {
    return GET('/au/pavlion/lindex', params)
}
export const homedata = async (params = {}) => {
    return GET('/au/pavlion/index', params)
}

// export function homedata() {
//     return axios({
//         url: '/au/pavlion/index',
//         method: "GET"
//     })
// }
export const listdata = async (params = {}) => {
    return GET('/au/pavlion/findP', params)
}

// export function listdata(id,varsen) {
//     return axios({
//         url: '/au/pavlion/findP',
//         method: "GET",
//         params:{
//             id:id,
//             version:varsen
//         }
//     })
// }

export const quryByRId = async (params = {}) => {
    return GET('/au/audio/quryByRId', params)
}
// export function quryByRId(id,varsen) {
//     return axios({
//         url: '/au/audio/quryByRId',
//         method: "GET",
//         params:{
//             relationId:id,
//             version:varsen
//         }
//     })
// }

export const towquryByRId = async (params = {}) => {
    return GET('/au/exhibition/queryByPId', params)
}

// export function  towquryByRId(id) {
//     return axios({
//         url: '/au/exhibition/queryByPId',
//         method: "GET",
//         params:{
//             pavilionId:id
//         }
//     })
// }


export const towqueryByEId = async (params = {}) => {
    return GET('/au/theme/queryByEId', params)
}

// export function  towqueryByEId(id,varsen) {
//     return axios({
//         url: '/au/theme/queryByEId',
//         method: "GET",
//         params:{
//             exhibitionId:id,
//             version:varsen
//         }
//     })
// }


export const towthreequeryByEId = async (params = {}) => {
    return GET('/au/pictures/quryByTId', params)
}
// export function  towthreequeryByEId(id,versen) {
//     return axios({
//         url: '/au/pictures/quryByTId',
//         method: "GET",
//         params:{
//             themeId:id,
//             version:versen
//         }
//     })
// }

export const towonequryByRpId = async (params = {}) => {
    return GET('/au/audio/quryByRpId', params)
}

// export function  towonequryByRpId(id,varsen) {
//     return axios({
//         url: '/au/audio/quryByRpId',
//         method: "GET",
//         params:{
//             relationId:id,
//             version:varsen
//         }
//     })
// }

export const inc = async (params = {}) => {
    return GET('/au/visit/inc', params)
}

// export function  inc(type,id,varsen) {
//     return axios({
//         url: '/au/visit/inc',
//         method: "GET",
//         params:{
//             type:type,
//             id:id,
//             version:varsen
//         }
//     })
// }


